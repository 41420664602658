<template>
  <div>
    <div v-if="!error" class="detail-wrapper">
      <div class="side-wrapper">
        <ul class="list-group list-group-flush">
          <a
            v-for="(col, i) in side_cols"
            :key="i"
            href="#"
            class="list-group-item list-group-item-action flex-column align-items-start side-item"
            v-bind:class="{ selected: i == colIdxSelected }"
            @click="sideColClicked(i)"
          >
            {{ col }}
          </a>
        </ul>
      </div>
      <div class="list-wrapper" v-on:scroll.passive="handleScroll">
        <div
          v-if="loading"
          class="d-flex justify-content-center align-items-center"
          style="height: 100%"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div
          v-if="json_data"
          class="text-left m-2 font-weight-bold fixed-title"
        >
          <h4>Locus: {{ json_data.name }}</h4>
        </div>
        <BaseEntry
          v-for="(e, i) in entries"
          :selectedColIdx="colIdxSelected"
          :currIdx="i"
          :key="i"
          :content="e"
          :ref="`entry` + i"
        ></BaseEntry>
      </div>
    </div>
    <div v-else>
      <PageNotFound />
    </div>
  </div>
</template>

<script>
//http://localhost:8082/locus?key=1
import axios from "axios";
import BaseEntry from "@/components/detail/BaseEntry";
import PostsService from "@/services/PostsService";
import PageNotFound from "@/components/common/PageNotFound";

import { getSlimViewUrl, getFullViewUrl } from "@/services/JBrowseService";

import * as annotationsSearch from "@/services/search/annotationsSearch";

export default {
  name: "LocusDetail",
  props: {
    url: String,
  },
  components: {
    BaseEntry,
    PageNotFound,
  },
  watch: {},
  data() {
    return {
      loading: false,
      error: false,
      base_url: process.env.VUE_APP_S3_PATH + "locus/",
      base_ortho_url: "https://phg-orthologs-17.s3-us-west-2.amazonaws.com",
      base_paralogs_url: "https://phg-paralogs-17.s3-us-west-2.amazonaws.com",
      json_data: null,
      ortho_data: null,
      paralogs_data: null,
      first: {
        title: "Summary",
        name: "ASJKDJDKK",
      },
      side_cols: [
        "Summary",
        "Transcripts",
        "Maps and Mapping Data",
        "Sequences",
        "Protein Data",
        "Expression",
        "Gene Ontology",
        "Homology",
        "Germplasm and Clones",
        "Polymorphisms",
        "Publications",
        "External Links",
      ],
      colIdxSelected: 0,
      entries: [],
      entryPosys: [],
    };
  },
  async mounted() {
    if (this.$route.query.key) {
      this.loadJson(this.$route.query.key);
    }

    if (this.$route.query.name) {
      let name = this.$route.query.name.toUpperCase();
      let response = await PostsService.getLocusIdByName(name);
      let locusId = response.data;
      if (locusId == null) {
        this.error = true;
      } else {
        this.loadJson(locusId);
      }
    }

    // New handling for 'accession' query parameter
    if (this.$route.query.accession) {
      let accession = this.$route.query.accession;
      let response = await PostsService.getDetailsByAccession(accession);
      let locusId = response.data;
      if (locusId == null) {
        this.error = true;
      } else {
        this.loadJson(locusId);
      }
    }

    // Error handling if none of the parameters are present
    if (
      !this.$route.query.key &&
      !this.$route.query.name &&
      !this.$route.query.accession
    ) {
      this.loading = false;
      this.error = true;
    }
  },
  created() {},
  methods: {
    async loadJson(locusId) {
      try {
        this.loading = true;
        this.json_data = (await this.getLocusDetail(locusId)).data;
      } catch (error) {
        console.error("Error fetching locus details: ", error);
        this.loading = false;
        this.error = true;
        return;
      }
      try {
        this.ortho_data = (await this.getOrthologs()).data;
      } catch (error) {
        // console.error("Error fetching ortholog details: ", error);
        this.ortho_data = null; // Ensure ortho_data is null if there's an error
      }
      try {
        this.paralogs_data = (await this.getParalogs()).data;
      } catch (error) {
        // console.error("Error fetching paralog details: ", error);
        this.paralogs_data = null; // Ensure paralogs_data is null if there's an error
      }
      this.loading = false;
      this.error = false;
      this.processEntries();
      document.title = `Locus: ${this.json_data.name}`;
      this.colIdxSelected = 0;
      this.updateEntryCounts();
      setTimeout(() => {
        this.entries.forEach((e, i) => {
          let entryPagePosY = this.$refs["entry" + i][0].$el.offsetTop;

          this.entryPosys.push(entryPagePosY);
        });
        this.loading = false;
      }, 1000);
    },
    scrollToElement(colIdx) {
      const entryRef = this.$refs["entry" + colIdx];
      const el = entryRef[0].$el;
      if (el) {
        var top = el.offsetTop - 210;
        el.parentElement.scrollTo(0, top);
      }
    },
    handleScroll(e) {
      let scrollTop = e.target.scrollTop + 280;
      let currTopEntry = 0;
      this.entryPosys.forEach((e, i) => {
        if (scrollTop > e) {
          currTopEntry = i;
        }
      });
      this.colIdxSelected = currTopEntry;
    },
    sideColClicked(colIdx) {
      this.scrollToElement(colIdx);
      this.colIdxSelected = colIdx;
    },
    async getLocusDetail(id) {
      return PostsService.getLocusDetail({ key: id });
    },
    async getOrthologs() {
      let url = this.base_ortho_url + "/" + this.json_data.name + ".json";

      return axios({
        method: "get",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
        url: url,
      });
    },
    async getParalogs() {
      let url = this.base_paralogs_url + "/" + this.json_data.name + ".json";
      return axios({
        method: "get",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
        url: url,
      });
    },
    async getOrthologsDownloadUrl() {
      return this.base_ortho_url + "/";
    },
    processEntries() {
      this.side_cols.forEach((title) => {
        let entry = {
          title: title,
          name: title.replace(/\s/g, ""),
        };
        switch (title) {
          default:
            entry.type = "bands";
            entry.bands = [];
            break;
          case "Summary":
            entry.type = "bands";
            entry.bands = this.fillSummary();
            break;
          case "Transcripts":
            entry.type = "bands";
            entry.bands = this.fillTranscripts();
            break;
          case "Maps and Mapping Data":
            entry.type = "bands";
            entry.bands = this.fillMapDetails();
            break;
          case "Sequences":
            entry.type = "bands";
            entry.bands = [this.getSequences()];
            break;
          case "Protein Data":
            entry.type = "bands";
            entry.bands = [this.getProteinData()];
            break;
          case "Expression":
            entry.type = "bands";
            entry.bands = [
              this.getPlantOntology(),
              this.getEFPBrowser(),
              this.getRNAData(),
            ];
            break;
          case "Gene Ontology":
            entry.type = "bands";
            entry.bands = [this.getGeneOntology()];
            break;
          case "Homology":
            entry.type = "bands";
            entry.bands = this.fillHomology();
            let paralogAgis = [];
            if (this.paralogs_data) {
              paralogAgis = this.paralogs_data.map((o) => {
                return o.target_agi;
              });
            }
            entry.meta = { treeId: this.json_data.name, agis: paralogAgis };
            break;
          case "Germplasm and Clones":
            entry.type = "bands";
            entry.bands = [this.getGermplasms(), this.getClones()];
            break;
          case "Polymorphisms":
            entry.type = "bands";
            entry.bands = [this.getPolymorphisms()];
            break;
          case "Publications":
            entry.type = "bands";
            entry.bands = [this.getPublications()];
            break;
          case "External Links":
            entry.type = "bands";
            entry.bands = [this.getExternalLinks()];
            break;
        }

        this.entries.push(entry);
      });
    },
    async updateEntryCounts() {
      //Update Gene Ontology count
      let key = "searchParams_go";
      let searchParams = JSON.parse(localStorage.getItem(key));
      let resp = await annotationsSearch.loadAnnotationsResults(searchParams);
      if (!resp.error) {
        let sectionFoundIndex = this.entries.findIndex(
          (e) => e.name === "GeneOntology"
        );
        if (sectionFoundIndex !== -1) {
          this.$set(
            this.entries[sectionFoundIndex].bands[0],
            "count",
            resp.items.length
          );
          this.$set(
            this.entries[sectionFoundIndex].bands[0],
            "entry_name",
            "unique annotations"
          );
        }
      }

      //Update Plant Ontology count
      key = "searchParams_po";
      searchParams = JSON.parse(localStorage.getItem(key));
      resp = await annotationsSearch.loadAnnotationsResults(searchParams);
      if (!resp.error) {
        let sectionFoundIndex = this.entries.findIndex(
          (e) => e.name === "Expression"
        );
        if (sectionFoundIndex !== -1) {
          this.$set(
            this.entries[sectionFoundIndex].bands[0],
            "count",
            resp.items.length
          );
          this.$set(
            this.entries[sectionFoundIndex].bands[0],
            "entry_name",
            "unique annotations"
          );
        }
      }
    },

    //Fill Functions
    fillSummary() {
      let geneModelType = this.json_data.repGene.geneModelType;
      let otherNames = "";
      if (this.json_data.otherNames) {
        this.json_data.otherNames.forEach((name, i) => {
          otherNames += name;
          if (i < this.json_data.otherNames.length - 1) {
            otherNames += ", ";
          }
        });
      }
      let updateHistories = [];
      if (this.json_data.updateHistories) {
        this.json_data.updateHistories.forEach((o) => {
          let log =
            o.tairObjectId +
            " " +
            o.updateType +
            " " +
            o.name +
            " on " +
            o.date;
          updateHistories.push(log);
        });
      }

      let description = this.json_data.repGene.description;
      let tairAccession = "";
      if (this.json_data.tairObjectId) {
        tairAccession = "Locus:" + this.json_data.tairObjectId;
      }

      let bands = [];
      // bands.push({
      //   key: "Locus",
      //   text: this.json_data.name
      // });
      bands.push({
        key: "Gene Model Type",
        text: geneModelType,
      });
      bands.push({
        key: "Other Names",
        text: otherNames,
      });
      bands.push({
        key: "Description",
        text: description,
      });
      bands.push(this.getComments());
      bands.push(this.getUpdateHistory());
      bands.push({
        key: "Date last modified",
        text: this.getDateModified(),
      });
      bands.push({
        key: "TAIR Accession",
        text: tairAccession,
      });
      return bands;
    },
    fillTranscripts() {
      let repGeneModel = this.json_data.repGene.name;
      let repGeneModelLink = "/gene?key=" + this.json_data.repGene.geneId;
      let bands = [];
      bands.push({
        key: "Representative Gene Model",
        type: "link",
        text: repGeneModel,
        link: repGeneModelLink,
      });
      if (this.json_data.otherGenes) {
        bands.push(this.getOtherGenes());
      }
      if (this.json_data.transcriptCounts) {
        bands.push(this.getAssociatedTranscripts());
      }

      bands.push(this.getAssociatedTransposons());
      return bands;
    },
    fillMapDetails() {
      let bands = [];
      bands.push(this.getMapDetails());
      if (this.json_data.globalAssignments) {
        bands.push(this.getMapLocations());
      }
      bands.push(this.getMapLinks());
      if (this.json_data.geneticMarkers) {
        bands.push(this.getGeneticMarkers());
      }
      return bands;
    },
    fillHomology() {
      let bands = [];
      bands.push(this.getGeneFamilies());
      bands.push(this.getPantherViewer());
      bands.push(this.getPlantHomologs());
      return bands;
    },

    getDateModified() {
      let date = new Date(this.json_data.dateLastModified);
      return this.dateToYMD(date);
    },
    dateToYMD(date) {
      var d = date.getDate();
      var m = date.getMonth() + 1; //Month from 0 to 11
      var y = date.getFullYear();
      return (
        "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d)
      );
    },
    getUpdateHistory() {
      let entry = {
        key: "Update History",
        type: "string_list",
        items: [],
      };
      if (this.json_data.updateHistories) {
        this.json_data.updateHistories.forEach((h) => {
          entry.items.push(
            this.json_data.name +
              " " +
              h.updateType +
              " " +
              h.name +
              " on " +
              h.date
          );
        });
      }
      if (this.json_data.affectedByHistories) {
        this.json_data.affectedByHistories.forEach((h) => {
          let name = h.name ? h.name : "";
          entry.items.push(
            this.json_data.name +
              " " +
              h.updateType +
              " " +
              name +
              " on " +
              h.date
          );
        });
      }
      if (entry.items.length == 0) {
        entry.items.push("No update history available");
      }
      return entry;
    },
    getComments() {
      let entry = {
        key: "Community Comments",
        type: "comment_list",
        showComments: false,
        showRecent: true,
        items: [],
      };
      let items = [];
      if (this.json_data.comments) {
        this.json_data.comments.forEach((c) => {
          let date = new Date(c.dateEntered);
          date = this.getDateModified(date);
          let item = {};
          item.profile = {
            name: c.person.name,
            url: `/person?key=${c.person.communityId}`,
          };
          item.comment = {
            text: c.notepadComment,
            url:
              process.env.VUE_APP_OLD_TAIR_URL +
              "/servlets/TairObject?type=notepad&id=" +
              c.notepadId,
          };
          item.date_posted = date;
          items.push(item);
        });
      }
      entry.items = items;
      if (entry.items.length == 0) {
        entry.items.push({ comment: { text: "No comments found" } });
      }
      return entry;
    },
    getMapDetails() {
      if (this.json_data.jbrowseChromosome == null) {
        let entry = {
          key: "Map Detail Image",
          text: "No map details found",
        };
        return entry;
      }
      let jbrowseChromosome = this.json_data.jbrowseChromosome;
      let spanStartPosition = this.json_data.spanStartPosition;
      let spanEndPosition = this.json_data.spanEndPosition;
      let geneModelType = this.json_data.repGene.geneModelType;
      let jbrowseUrl = getSlimViewUrl(
        jbrowseChromosome,
        spanStartPosition,
        spanEndPosition,
        geneModelType
      );
      let fullScreenUrl = getFullViewUrl(
        jbrowseChromosome,
        spanStartPosition,
        spanEndPosition,
        geneModelType
      );
      let entry = {
        key: "Map Detail Image",
        // text: "value",
        type: "iframe",
        name: this.json_data.name,
        jbrowseUrl: jbrowseUrl,
        fullScreenUrl: fullScreenUrl,
      };
      return entry;
    },
    //protein data
    getProteinData() {
      let entry = {
        key: "Protein Data",
        type: "table",
        items: [],
        fields: [
          { key: "name_link", label: "Name", cellType: "name_link" },
          { key: "length", label: "Length" },
          { key: "molecular_weight", label: "Molecular Weight" },
          { key: "isoelectric_point", label: "Isoelectric Point" },
          {
            key: "link_list",
            label: "INTERPRO domains",
            cellType: "link_list",
          },
        ],
      };

      if (this.json_data.proteinSequences) {
        this.json_data.proteinSequences.forEach((g) => {
          let item = {};
          let link = "/protein?key=" + g.tairObjectId;
          item.name_link = { name: g.name, link: link };
          item.length = g.length;
          item.molecular_weight = g.molecularWeight;
          item.isoelectric_point = g.isoelectricPoint;
          let links = [];
          if (g.domains) {
            g.domains.forEach((d) => {
              links.push({
                name: d.displayName,
                link:
                  "http://www.ebi.ac.uk/interpro/entry/InterPro/" + d.accession,
              });
            });
          }
          item.link_list = { link_list: links };
          entry.items.push(item);
        });
      }

      if (entry.items.length == 0) {
        entry.type = "";
        entry.text = "No protein data available";
      }
      return entry;
    },

    //Map links
    getMapLinks() {
      let entry = {
        key: "Map Links",
        type: "link_list",
        items: [],
      };
      entry.items.push({
        text: "MapViewer",
        link:
          process.env.VUE_APP_OLD_TAIR_URL +
          "/servlets/mapper?value=" +
          this.json_data.name +
          "&action=search",
      });
      // entry.items.push({
      //   text: "Sequence Viewer",
      //   link:
      //     "https://seqviewer.arabidopsis.org/?action=accession&type=gene&id=" +
      //     this.json_data.repGene.tairObjectId +
      //     "&chr=" +
      //     this.json_data.chromosome,
      // });
      // entry.items.push({
      //   text: "GBrowse",
      //   link:
      //     "https://gbrowse.arabidopsis.org/cgi-bin/gb2/gbrowse/arabidopsis/?name=" +
      //     this.json_data.name,
      // });
      let jbrowseChromosome = this.json_data.jbrowseChromosome;
      let spanStartPosition = this.json_data.spanStartPosition;
      let spanEndPosition = this.json_data.spanEndPosition;
      let geneModelType = this.json_data.repGene.geneModelType;
      let jbrowseUrl = getFullViewUrl(
        jbrowseChromosome,
        spanStartPosition,
        spanEndPosition,
        geneModelType
      );
      entry.items.push({
        text: "JBrowse",
        link: jbrowseUrl,
      });

      let seqviewerUrl = `${process.env.VUE_APP_OLD_TAIR_URL}/servlets/tools/sv?action=accession&type=gene&id=${this.json_data.repGene.tairObjectId}&chr=${this.json_data.chromosome}`;
      entry.items.push({
        text: "SeqViewer",
        link: seqviewerUrl,
      });
      return entry;
    },
    //sequences
    getSequences() {
      let entry = {
        key: "Sequence",
        type: "link_list",
        items: [],
      };
      let nsq = this.json_data.nucleotideSequences;
      if (nsq && nsq.length > 0) {
        nsq.forEach((n) => {
          entry.items.push({
            text: n.name,
            link: "/sequence?key=" + n.nucleotideSequenceId,
          });
        });
      }

      let psq = this.json_data.proteinSequences;
      if (psq && psq.length > 0) {
        psq.forEach((p) => {
          entry.items.push({
            text: "protein",
            link: "/protein?key=" + p.tairObjectId,
          });
        });
      }
      if (entry.items.length == 0) {
        entry.type = "";
        entry.text = "No sequences available";
      }

      return entry;
    },
    //otherGenes
    getOtherGenes() {
      let entry = {
        key: "Other Gene Models",
        type: "small-table",
        items: [],
        fields: [],
      };
      if (this.json_data.otherGenes) {
        entry.fields.push({
          key: "name_link",
          label: "Gene Name",
          cellType: "name_link",
          helpTxt:
            "This section displays information about the gene model(s) associated to the displayed data (such as the gene associated to an allele, gene models for a locus or genes contained within a clone). Hyperlinks from the gene name will open gene detail pages for the associated genes.",
        });
        entry.fields.push({
          key: "isSplice",
          label: "Is Splice Variant",
          cellType: "name_link",
        });
        this.json_data.otherGenes.forEach((g) => {
          let item = {};
          let url = "/gene?key=" + g.geneId;
          item.name_link = { name: g.name, link: url };
          item.isSplice = { name: g.isSpliceVariant };
          entry.items.push(item);
        });
      }

      return entry;
    },
    //Associated Transcripts
    getAssociatedTranscripts() {
      let entry = {
        key: "Associated Transcripts",
        type: "table",
        items: [],
        fields: [],
      };
      if (this.json_data.transcriptCounts) {
        entry.fields.push({
          key: "type",
          label: "Type",
          cellType: "name_link",
        });
        entry.fields.push({
          key: "Transcript Count",
          label: "Transcript Count",
          cellType: "name_link",
        });
        this.json_data.transcriptCounts.forEach((g) => {
          let item = {};
          item.type = { name: g.transcriptType };
          let locusId = this.json_data.locusId;
          let link =
            process.env.VUE_APP_OLD_TAIR_URL +
            "/servlets/Search?action=search&type=dna&other_features=is_sequenced&locus_id=" +
            locusId +
            "&dna_type=clone_end&clone_end_type=" +
            g.transcriptType;
          item["Transcript Count"] = {
            name: "(" + g.transcriptCount + ")",
            link: link,
          };
          entry.items.push(item);
        });
      }

      return entry;
    },
    //Associated Transposons
    getAssociatedTransposons() {
      let entry = {
        key: "Associated Transposons",
        type: "link",
      };
      if (this.json_data.transposons && this.json_data.transposons.length > 0) {
        entry.text = this.json_data.transposons[0].name;
        entry.link = "www.google.com";
      } else {
        entry.type = null;
        entry.text = "No Associated Transposons found";
      }
      return entry;
    },
    //Maps and Mapping Data
    getMapLocations() {
      let entry = {
        key: "Map Locations",
        type: "table",
        items: [],
        fields: [
          { key: "chrom", label: "Chromosome" },
          { key: "map_type", label: "Map Type" },
          { key: "map", label: "Map Name" },
          { key: "orientation", label: "Orientation" },
          { key: "coordinates", label: "Coordinates" },
        ],
      };

      if (
        this.json_data.globalAssignments &&
        this.json_data.globalAssignments.length > 0
      ) {
        this.json_data.globalAssignments.forEach((a) => {
          let item = {};
          if (a.map) {
            item.chrom = a.map.chromosome;
            item.map_type = a.map.mapType;
            item.map = a.map.name;
          }
          item.orientation = a.orientation;
          item.coordinates =
            a.startPosition + " - " + a.endPosition + " " + a.unit;
          entry.items.push(item);

          //super assignments
          if (a.superAssignments && a.superAssignments.length > 0) {
            a.superAssignments.forEach((sa) => {
              let item = {};
              if (sa.superMap) {
                item.chrom = sa.superMap.linkId;
                item.map = sa.superMap.name;
              }
              item.map_type = sa.mapElementType;
              item.orientation = sa.orientation;
              item.coordinates =
                sa.startPosition + " - " + sa.endPosition + " " + sa.unit;
              entry.items.push(item);
            });
          }
        });
      }
      return entry;
    },

    getGeneticMarkers() {
      let entry = {
        key: "Genetic Markers",
        type: "table",
        items: [],
        fields: [
          { key: "name_link", label: "Name", cellType: "name_link" },
          { key: "type", label: "Type" },
          { key: "alias", label: "Alias" },
          { key: "chromosome", label: "Chromosome" },
          { key: "position", label: "Position" },
          { key: "name_link2", label: "Map Viewer", cellType: "name_link" },
          { key: "name_link3", label: "Seq Viewer", cellType: "name_link" },
        ],
      };

      let geneticMarkers = this.json_data.geneticMarkers;
      if (geneticMarkers && geneticMarkers.length > 0) {
        geneticMarkers.forEach((g) => {
          let item = {};
          let link_txt =
            process.env.VUE_APP_OLD_TAIR_URL +
            "/servlets/TairObject?accession=marker:" +
            g.tairObjectId;
          item.name_link = { name: g.name, link: link_txt };
          item.type = g.type;
          if (g.aliases && g.aliases.length > 0) {
            item.alias = g.aliases.join(", ");
          }

          item.chromosome = g.chromosome;
          let posn = "";
          g.globalAssignments.forEach((ga) => {
            posn +=
              ga.startPosition + "-" + ga.endPosition + " " + ga.units + ",";
          });
          item.position = posn;
          link_txt =
            process.env.VUE_APP_OLD_TAIR_URL +
            "/servlets/mapper?value=" +
            this.json_data.name +
            "&action=search";
          item.name_link2 = { name: "map viewer", link: link_txt };

          if (g.agiChromosomes) {
            link_txt =
              "https://seqviewer.arabidopsis.org/?action=accession&type=marker&id=" +
              g.tairObjectId +
              "&chr=" +
              g.agiChromosomes[0];
            // item.name_link3 = { name: "seq viewer", link: link_txt };
          }

          entry.items.push(item);
        });
      }
      return entry;
    },

    getEFPBrowser() {
      let entry = {
        key: "BAR eFP Browser",
        // type: "iframe",
        type: "image",
        metadata: {
          locusName: this.json_data.name,
        },
        types: [
          "Abiotic Stress II",
          "Abiotic Stress",
          "Biotic Stress II",
          "Biotic Stress",
          "Chemical",
          "Developmental_Map",
          "Developmental_Mutants",
          "Development_RMA",
          "Germination",
          "Guard_Cell",
          "Hormone",
          "Klepikova_Atlas",
          "Lateral_Root_Initiation",
          "Light_Series",
          "Natural_Variation",
          "Regeneration",
          "Root_II",
          "Root",
          "Seed",
          "Shoot_Apex",
          "Single_Cell",
          "Tissue_Specific",
        ],
        selectedType: "Klepikova_Atlas",
      };
      return entry;
    },
    getRNAData() {
      let entry = {
        key: "Array Elements",
        type: "table",
        items: [],
        fields: [],
      };
      entry.fields.push({
        key: "single",
        label: "Single Channel Array Elements",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "dual",
        label: "Dual Channel Array Elements",
        cellType: "name_link",
      });

      let single = this.json_data.singleChannelArrayElements;
      let dual = this.json_data.dualChannelArrayElements;

      if (!single && !dual) {
        let entry = {
          key: "RNA Data",
          text: "No RNA Data Found",
        };
        return entry;
      }

      let maxLength = Math.max(
        single ? single.length : 0,
        dual ? dual.length : 0
      );
      for (let i = 0; i < maxLength; i++) {
        let item = {};
        let s = single ? single[i] : null;
        if (s) {
          item.single = {
            name: s.name,
            link:
              "/array_element?key=" + s.name.toLowerCase().replace(/\s+/g, "_"),
          };
        }
        let d = dual ? dual[i] : null;
        if (d) {
          item.dual = {
            name: d.name,
            link:
              "/array_element?key=" + d.name.toLowerCase().replace(/\s+/g, "_"),
          };
        }

        entry.items.push(item);
      }

      return entry;
    },
    //Gene Ontology (GO)
    getGeneOntology() {
      let entry = {
        key: "",
        type: "table",
        items: [],
        fields: [],
        buttons: [],
      };
      let annotationGroups = this.json_data.annotationGroups;
      if (annotationGroups && annotationGroups.length > 0) {
        entry.fields.push({
          key: "category",
          label: "Category",
          cellType: "name_link",
        });
        entry.fields.push({
          key: "relationshipType",
          label: "Relationship Type",
          cellType: "name_link",
        });
        entry.fields.push({
          key: "link_list",
          label: "Keyword",
          cellType: "link_list",
        });

        annotationGroups.forEach((g) => {
          let item = {};
          if (
            g.category != "Growth and Developmental Stages" &&
            g.category != "Plant structure"
          ) {
            item.category = { name: g.category };
            item.relationshipType = { name: g.relationshipType };
            let keywords = [];
            g.annotations.forEach((a) => {
              // keyword += a.keyword + ", ";
              let link_url = `/keyword?key=${a.keywordId}`;
              keywords.push({ name: a.keyword, link: link_url });
            });

            item.link_list = { link_list: keywords };

            entry.items.push(item);
          }
        });
      }

      let searchParams = {
        keyword_types: ["comp", "func", "proc"],
        searchTerms: [
          {
            search_type: "locus_name",
            search_method: "exactly",
            search_input: this.json_data.name,
          },
        ],
      };
      let key = "searchParams_go";
      localStorage.setItem(key, JSON.stringify(searchParams));
      let link = `/results?mainType=detail&category=annotations&key=${key}`;

      entry.buttons.push({
        name: "View Annotation Details",
        url: link,
      });
      return entry;
    },
    //Expression: annotationGroups (PO)
    getPlantOntology() {
      let entry = {
        key: "Plant Ontology Annotations",
        type: "table",
        items: [],
        fields: [],
        buttons: [],
      };
      let annotationGroups = this.json_data.annotationGroups;
      if (annotationGroups && annotationGroups.length > 0) {
        entry.fields.push({
          key: "category",
          label: "Category",
          cellType: "name_link",
        });
        entry.fields.push({
          key: "relationshipType",
          label: "Relationship Type",
          cellType: "name_link",
        });
        entry.fields.push({
          key: "link_list",
          label: "Keyword",
          cellType: "link_list",
        });
        annotationGroups.forEach((g) => {
          let item = {};
          if (
            g.category == "Growth and Developmental Stages" ||
            g.category == "Plant structure"
          ) {
            item.category = { name: g.category };
            item.relationshipType = { name: g.relationshipType };
            let keywords = [];
            g.annotations.forEach((a) => {
              let link_url = `keyword?key=${a.keywordId}`;
              keywords.push({ name: a.keyword, link: link_url });
            });
            item.link_list = { link_list: keywords };

            entry.items.push(item);
          }
        });
      }
      let searchParams = {
        keyword_types: ["grow", "stru"],
        searchTerms: [
          {
            search_type: "locus_name",
            search_method: "exactly",
            search_input: this.json_data.name,
          },
        ],
      };
      let key = "searchParams_po";
      localStorage.setItem(key, JSON.stringify(searchParams));
      let link = `/results?mainType=detail&category=annotations&key=${key}`;
      entry.buttons.push({
        name: "View Annotation Details",
        url: link,
      });
      return entry;
    },
    //Homology
    getGeneFamilies() {
      let entry = {
        key: "Gene Families",
        type: "link_list",
        items: [],
      };
      entry.items.push({
        text: "EnsemblPlants",
        link:
          "http://plants.ensembl.org/Arabidopsis_thaliana/Gene/Compara_Tree?g=" +
          this.json_data.name,
      });
      entry.items.push({
        text: "PhyloGenes",
        link: "http://www.phylogenes.org/gene-id/" + this.json_data.name,
      });
      entry.items.push({
        text: "InParanoid Ortholog Groups",
        link:
          "http://inparanoid.sbc.su.se/cgi-bin/gene_search.cgi?scorelimit=0.05&idtype=proteinid&id=" +
          this.json_data.name +
          ".2&all_or_selection=all",
      });
      entry.items.push({
        text: "Phytozome",
        link:
          "https://phytozome.jgi.doe.gov/pz/portal.html#!results?search=0&crown=1&star=1&method=0&offset=0&searchText=" +
          this.json_data.name,
      });
      //phylomedb.org/?q=search_tree&seqid=AT5G53860
      http: entry.items.push({
        text: "PANTHER",
        link:
          "http://www.pantherdb.org/panther/globalSearch.do?searchType=basic&fieldName=all&organism=all&listType=-1&fieldValue=" +
          this.json_data.name,
      });
      entry.items.push({
        text: "PLAZA",
        link:
          "https://bioinformatics.psb.ugent.be/plaza/versions/plaza_v5_dicots/genes/view/" +
          this.json_data.name,
      });
      // entry.items.push({
      //   text: "PGDD duplications and orthologs",
      //   link:
      //     "http://chibba.agtec.uga.edu/duplication/index/locus_app?lc=" +
      //     this.json_data.name,
      // });
      return entry;
    },
    getPantherViewer() {
      let pg_link = "http://www.phylogenes.org/gene-id/" + this.json_data.name;

      if (!this.json_data.hasPhylogenesPage) {
        return {
          key: "Panther Tree Viewer",
          text: "No Panther Gene Family found",
        };
      }
      let entry = {
        key: "Panther Tree Viewer",
        type: "panther_tree_viewer",
        pgLink: pg_link,
      };
      return entry;
    },
    //Panther homologs
    getPlantHomologs() {
      let vals = {
        paralogs: [],
        eudic_ortho: {
          title: "Eudicotyledons",
          mapping: {},
        },
        mono_ortho: {
          title: "Monocotyledons",
          mapping: {},
        },
        other_ortho: {
          title: "Other plants",
          mapping: {},
        },
        orthoDownloadUrl: this.getOrthologsDownloadUrl(),
      };

      let entry = {
        key: "PANTHER Plant Homologs",
        type: "homologs",
        info: "Data displayed in this section are derived from the gene families of PANTHER 17.0 release.",
        content: vals,
      };
      if (!this.ortho_data || this.ortho_data.length == 0)
        return {
          key: "PANTHER Plant Homologs",
          text: "This locus does not have any plant homologs in PANTHER.",
        };
      let eudic_ortho = {};
      let mono_ortho = {};
      let other_ortho = {};
      this.ortho_data.forEach((o) => {
        let fullName = o.full_name;
        if (o.group_name == "eudicotyledons") {
          if (Object.keys(eudic_ortho).includes(fullName)) {
            eudic_ortho[fullName] = eudic_ortho[fullName] + 1;
          } else {
            eudic_ortho[fullName] = 1;
          }
        } else if (o.group_name == "monocotyledons") {
          if (Object.keys(mono_ortho).includes(fullName)) {
            mono_ortho[fullName] = mono_ortho[fullName] + 1;
          } else {
            mono_ortho[fullName] = 1;
          }
        } else if (o.group_name == "other plants") {
          if (Object.keys(other_ortho).includes(fullName)) {
            other_ortho[fullName] = other_ortho[fullName] + 1;
          } else {
            other_ortho[fullName] = 1;
          }
        }
      });
      vals.eudic_ortho.mapping = eudic_ortho;
      vals.mono_ortho.mapping = mono_ortho;
      vals.other_ortho.mapping = other_ortho;
      if (!this.paralogs_data || this.paralogs_data.length == 0) {
        return entry;
      }

      this.paralogs_data.forEach((p) => {
        let obj = {};
        obj.name = p.target_agi + " (" + p.target_gene_symbol + ")";
        obj.link = "/locus?name=" + p.target_agi;
        vals.paralogs.push(obj);
      });
      return entry;
    },
    //Germplasn
    getGermplasms() {
      let entry = {
        key: "Germplasms",
        type: "multiline",
        items: [],
        fields: [],
        buttons: [],
        helpTxt:
          "In TAIR, germplasms correspond to individual strains having unique genotypes, pooled strains and sets of pools and strains.",
      };
      let germplasms = this.json_data.germplasms;
      if (!germplasms || germplasms.length == 0) {
        return {
          key: "Germplasms",
          text: "No Germplasms found",
        };
      }
      entry.fields.push({
        key: "name",
        label: "Name",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "poly",
        label: "Polymorphisms",
        cellType: "link_list",
      });
      entry.fields.push({
        key: "background",
        label: "Background",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "stock",
        label: "Stock Name",
        cellType: "link_list",
      });
      entry.fields.push({
        key: "phenotypes",
        label: "Phenotypes",
        cellType: "bullet_list",
        width: "100%",
      });
      entry.fields.push({
        key: "images",
        label: "Images",
        cellType: "image_list",
        width: "100%",
      });
      if (germplasms && germplasms.length > 0) {
        let maxToShow = 15;
        let germplasmsToShow = germplasms.slice(0, maxToShow);
        germplasmsToShow.forEach((g) => {
          // console.log(g.name, g);
          let item = {};
          item.name = { name: g.name, link: `germplasm?key=${g.tairObjectId}` };
          let polyList = [];
          if (g.polymorphisms) {
            g.polymorphisms.forEach((p) => {
              polyList.push({
                name: p.name,
                link: `polyallele?key=${p.polymorphismId}`,
              });
            });
          }
          item.poly = polyList;
          if (g.stockNames) {
            let stockList = [];
            g.stockNames.forEach((s) => {
              stockList.push({
                name: s,
                link: `https://abrc.osu.edu/stocks/number/${s}`,
              });
            });
            item.stock = stockList;
          }
          item.background = { name: g.speciesVariantAbbrevName };
          let phenotypes = [];
          if (g.phenotypeGroups) {
            g.phenotypeGroups.forEach((p) => {
              let bulletObj = {};
              if (p.label) {
                bulletObj.title = p.label;
                if (p.referenceId && p.label != "Phenotype curated by ABRC.") {
                  bulletObj.link = `publication?key=${p.referenceId}`;
                }
              }
              // let pobj = { label: p.label };
              bulletObj.points = [];
              p.phenotypes.forEach((pl) => {
                let point_html = "<span>" + pl + "</span>";
                bulletObj.points.push(point_html);
              });
              phenotypes.push(bulletObj);
            });
          }
          item.phenotypes = phenotypes;
          let images = [];
          if (g.images) {
            // console.log(g.images.length);
            g.images.forEach((gi) => {
              // console.log(gi);
              images.push({
                img_url:
                  process.env.VUE_APP_OLD_TAIR_URL +
                  "/servlets/images/thumb_" +
                  gi.imageId +
                  ".jpg",
                full_url:
                  process.env.VUE_APP_OLD_TAIR_URL +
                  "/servlets/images/" +
                  gi.imageId +
                  ".jpg",
              });
            });
          }
          item.images = images;
          // console.log(g.name, item.images.length);
          entry.items.push(item);
        });
        entry.count = germplasms.length;

        if (germplasms.length > maxToShow) {
          let search_params = {
            locusList: [this.json_data.name],
          };
          let encodedParams = encodeURIComponent(JSON.stringify(search_params));
          let link = `/results?mainType=detail&category=germplasm&search_params=${encodedParams}`;
          entry.buttons.push({
            name: `View Complete List`,
            url: link,
          });
          entry.maxShow = maxToShow;
        }
      }
      entry.buttons.push({
        name: "Search ABRC",
        url: "https://abrc.osu.edu/researchers",
      });
      entry.buttons.push({
        name: "Search RIKEN",
        url: "https://epd.brc.riken.jp/en/",
      });
      entry.buttons.push({
        name: "Search NASC",
        url: "https://arabidopsis.info/BasicForm",
      });
      return entry;
    },
    getClones() {
      let entry = {
        key: "Clones",
        type: "table",
        fields: [],
        items: [],
        buttons: [],
        showScroll: false,
      };

      let clones = this.json_data.clones;
      if (!clones || clones.length == 0) {
        return {
          key: "Clones",
          text: "No Clones found",
        };
      }
      // let names = clones.map((g) => g.name);
      // //sort by name
      // names.sort((a, b) => {
      //   return a.localeCompare(b);
      // });
      // console.log(names);
      entry.fields.push({
        key: "name_link",
        label: "Clone Name",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "aliases",
        label: "Aliases",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "stockNames",
        label: "Stock Number",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "vectorType",
        label: "Vector Type",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "accessions",
        label: "GenBank Accession",
        cellType: "name_link",
      });
      let maxToShow = 15;
      let clonesShown = clones.slice(0, maxToShow);
      clonesShown.forEach((c) => {
        let item = {};
        item.name_link = { name: c.name, link: `clone?key=${c.cloneId}` };
        item.aliases = { name: c.aliases ? c.aliases.join(", ") : "" };
        if (c.sequenceAccessions && c.sequenceAccessions.length > 0) {
          let url =
            "https://www.ncbi.nlm.nih.gov/nucleotide/" +
            c.sequenceAccessions[0];
          item.accessions = { name: c.sequenceAccessions[0], link: url };
        }
        item.vectorType = { name: c.vectorType };
        if (c.stockNames && c.stockNames.length > 0) {
          let url = "https://abrc.osu.edu/stocks/number/" + c.stockNames[0];
          item.stockNames = { name: c.stockNames[0], link: url };
        }

        entry.items.push(item);
      });

      entry.count = clones.length;
      if (clones.length > maxToShow) {
        let search_params = {
          category: "clone",
          searchTerms: [
            {
              search_type: "locus_name",
              search_method: "exactly",
              search_input: this.json_data.name,
            },
          ],
        };
        let encodedParams = encodeURIComponent(JSON.stringify(search_params));
        let link = `/results?mainType=detail&category=dna&search_params=${encodedParams}`;
        entry.buttons.push({
          name: `View Complete List`,
          url: link,
        });
        entry.maxShow = maxToShow;
      }
      entry.buttons.push({
        name: "Search ABRC",
        url: "https://abrc.osu.edu/researchers",
      });
      entry.buttons.push({
        name: "Search RIKEN",
        url: "https://epd.brc.riken.jp/en/pdna",
      });
      return entry;
    },
    //Polymorphisms
    getPolymorphTypes() {
      let polymorphismTypes = [];
      polymorphismTypes.push({
        label: "compound",
        desc: "Refers to polymorphisms between two lines where an insertion and a substitution or a deletion and a substitution occur at the same genomic location.",
      });
      polymorphismTypes.push({
        label: "deletion",
        desc: "A variation where a segment of DNA is absent in one variant with respect to a reference polymorphism. In TAIR the reference polymorphism corresponds to the wild type Columbia ecotype but other reference ecotypes may be used if the Columbia variant is not known.",
      });
      polymorphismTypes.push({
        label: "digest_pattern",
        desc: "The characteristic pattern of products of a restriction enzyme digestion. Also refers to a type of polymorphism where the pattern of restriction enzyme fragments in one variant differs from that of another variant.",
      });
      polymorphismTypes.push({
        label: "INDEL",
        desc: "Insertion/deletions (INDELS) are sequence variants where the Columbia (reference) polymorphism is an insertion relative to one ecotype and a deletion relative to another, different ecotype.",
      });
      polymorphismTypes.push({
        label: "insertion",
        desc: "A variant in which a segment of DNA is present in one genotype and absent in another. In TAIR, insertions are defined with respect to a reference polymorphism. The reference polymorphism typically corresponds to the wild type Columbia variant but other reference ecotypes may be used if the Columbia variant is not known.",
      });
      polymorphismTypes.push({
        label: "PCR_product_length",
        desc: "Refers to polymorphisms that are represented by different PCR product lengths. The polymorphisms are linked to the genetic markers (SSLP, AFLP) that can be used to detect them",
      });
      polymorphismTypes.push({
        label: "substitution",
        desc: "Replacement of one nucleotide sequence by another nucleotide or one amino acid in a protein by another amino acid. Also known as a single nucleotide polymorphism (SNP).",
      });
      polymorphismTypes.push({
        label: "visible",
        desc: "Allelic variations in a visible or biochemical phenotype at one or more loci that can be detected by quantitative or qualitative methods.",
      });
      return polymorphismTypes;
    },
    getAlleleTypes() {
      let alleleTypes = [];
      alleleTypes.push({
        label: "antimorphic",
        desc: "A type of mutation in which the mutated gene product has an altered function that acts antagonistically to the wild type allele. Antimorphic mutants are usually dominant or semi-dominant.",
      });
      alleleTypes.push({
        label: "gain of function",
        desc: "A type of mutation in which the altered gene product possesses a new function or a new pattern of gene expression. Gain of function mutants are usually dominant or semidominant.",
      });
      alleleTypes.push({
        label: "haplo-insufficient",
        desc: "A description applied to a gene that produces a mutant phenotype when present in a diploid individual heterozygous for an amorphic allele.",
      });
      alleleTypes.push({
        label: "hypermorphic",
        desc: "A type of mutation in which the altered gene product possesses an increased level of activity, or in which the wild-type gene product is expressed at a increased level.",
      });
      alleleTypes.push({
        label: "hypomorphic",
        desc: "A type of mutation in which the altered gene product possesses an increased level of activity, or in which the wild-type gene product is expressed at a increased level.",
      });
      alleleTypes.push({
        label: "loss of function",
        desc: "A type of mutation in which the altered gene product lacks the function of the wild-type gene. Also called amorphic or null mutation.",
      });
      alleleTypes.push({
        label: "unknown",
        desc: "Used when the type of the allele is not known or the information is not yet available.",
      });
      return alleleTypes;
    },
    getPolymorphisms() {
      let entry = {
        key: "",
        type: "table",
        items: [],
        fields: [],
        buttons: [],
        showScroll: false,
      };
      let polymorphisms = this.json_data.polymorphisms;
      if (polymorphisms && polymorphisms.length > 0) {
        //debug
        let names = polymorphisms.map((g) => g.name);
        //sort by name
        names.sort((a, b) => {
          return a.localeCompare(b);
        });
        // console.log(names);
        //debug
        let nameHelpTxt = `<div class="d-flex flex-column p-2" style="height: 200px; overflow-y: auto;">
          <p class="w-100 text-left"><b>name:</b>The name of a polymorphism such as an allele name, or an alphanumeric identifying code (e.g. for SALK T-DNA insertions or TILLing lines) or the name of the marker used to detect the polymorphism.</p>`;
        entry.fields.push({
          key: "name_link",
          label: "Name",
          cellType: "name_link",
          helpTxt: nameHelpTxt,
        });
        let polymorphismTypes = this.getPolymorphTypes();
        let polyHelpTxt = `<div class="d-flex flex-column p-2" style="height: 200px; overflow-y: auto;">
          <p class="w-100 text-left"><b>type:</b>Types of polymorphisms in TAIR.</p>`;
        polyHelpTxt += `<ul class="w-100 text-left">`;
        polymorphismTypes.forEach((p) => {
          polyHelpTxt += `<li><b>${p.label}</b>: ${p.desc}</li>`;
        });
        polyHelpTxt += `</ul></div>`;
        entry.fields.push({
          key: "poly_type",
          label: "Type",
          cellType: "name_link",
          helpTxt: polyHelpTxt,
        });
        entry.fields.push({
          key: "polymorphismSite",
          label: "Polymorphism Site",
          cellType: "name_link",
        });
        entry.fields.push({
          key: "polymorphismSite",
          label: "Polymorphism Site",
        });
        entry.fields.push({
          key: "gene_names",
          label: "Gene Names",
          cellType: "link_commasep",
        });
        let alleleHelpTxt = `<div class="d-flex flex-column p-2" style="height: 200px; overflow-y: auto;">
          <p class="w-100 text-left"><b>allele type:</b> A classification of alleles based upon the phenotype and genotype of alleles. </p>`;
        alleleHelpTxt += `<ul class="w-100 text-left">`;
        let alleleTypes = this.getAlleleTypes();
        alleleTypes.forEach((p) => {
          alleleHelpTxt += `<li><b>${p.label}</b>: ${p.desc}</li>`;
        });
        alleleHelpTxt += `</ul></div>`;
        entry.fields.push({
          key: "allele_type",
          label: "Allele Type",
          cellType: "name_link",
          helpTxt: alleleHelpTxt,
        });
        let maxToShow = 15;
        if (polymorphisms.length > maxToShow) {
          let search_params = {
            searchTerms: [
              {
                search_type: "locus_name",
                search_method: "exactly",
                search_input: this.json_data.name,
              },
            ],
          };
          let encodedParams = encodeURIComponent(JSON.stringify(search_params));
          let link = `/results?mainType=detail&category=polyallele&search_params=${encodedParams}`;
          entry.buttons.push({
            name: `View Complete List`,
            url: link,
          });
          entry.maxShow = maxToShow;
        }
        // let polyToShow = polymorphisms.slice(0, maxToShow);
        polymorphisms.forEach((c) => {
          if (c.featureGeneGroups) {
            c.featureGeneGroups.forEach((cg) => {
              let item = {};
              item.poly_type = { name: c.type };
              item.polymorphismSite = { name: cg ? cg.geneFeatureSite : "" };
              let genes = [];
              if (c.featureGeneGroups) {
                cg.geneNames.forEach((name) => {
                  genes.push({ name: name, link: `/gene?name=${name}` });
                });
              }
              item.gene_names = { link_commasep: genes };
              let link = `/polyallele?key=${c.polymorphismId}`;
              item.name_link = { name: c.name, link: link };
              item.allele_type = { name: c.alleleMode };
              entry.items.push(item);
            });
          }
        });
        entry.items = entry.items.slice(0, maxToShow);
        entry.count = polymorphisms.length;
      }
      if (entry.items.length == 0) {
        entry.type = "";
        entry.text = "No polymorphisms available";
      }
      return entry;
    },
    getPublications() {
      let entry = {
        key: "",
        type: "table",
        items: [],
        fields: [],
        buttons: [],
        showScroll: false,
      };
      let pubs = this.json_data.publications;
      if (pubs && pubs.length > 0) {
        entry.fields.push({
          key: "publication",
          label: "Author/Title",
          cellType: "html",
        });
        entry.fields.push({
          key: "source",
          label: "Source",
          cellType: "name_link",
        });
        entry.fields.push({
          key: "loci",
          label: "Associated Loci",
          cellType: "link_commasep",
        });
        entry.fields.push({ key: "date", label: "Date" });
        entry.count = pubs.length;
        let maxToShow = 15;
        if (pubs.length > maxToShow) {
          let search_params = { loci: this.json_data.name };
          let encodedParams = encodeURIComponent(JSON.stringify(search_params));
          let link = `/results?mainType=detail&category=publication&search_params=${encodedParams}`;
          entry.buttons.push({
            name: `View Complete List`,
            url: link,
          });

          entry.maxShow = maxToShow;
        } else {
          entry.maxShow = pubs.length;
        }
        let pubsToShow = pubs.slice(0, 15);
        pubsToShow.forEach((p) => {
          let item = {};
          let authorsText =
            p.authors.split(",").slice(0, 6).join(", ") + " et al.";
          let pub_link = `/publication?key=${p.referenceId}`;
          let pub_html = `<a href="${pub_link}">${authorsText}</a> <br/> ${p.title}`;
          item.publication = {
            html: pub_html,
            sortField: authorsText,
          };
          item.source = { name: p.publicatedBy };
          item.date = p.publicationYear;
          let link_list = [];
          let namesMax = p.locusNames.slice(0, 21);

          namesMax.forEach((n) => {
            let link = "/locus?name=" + n;
            link_list.push({ name: n, link: link });
          });
          if (p.locusNames.length > 19) {
            link_list.push({ name: "... more", link: pub_link });
          }

          item.loci = { link_commasep: link_list };
          entry.items.push(item);
        });
      }
      return entry;
    },
    getExternalLinks() {
      let entry = {
        key: "External Link",
        type: "table",
        items: [],
        fields: [],
        showScroll: false,
      };
      let links = this.json_data.externalLinkGroups;
      let count = 0;
      entry.fields.push({
          key: "type",
          label: "Type",
          cellType: "name_link",
        });
        entry.fields.push({
          key: "link_list",
          label: "Links",
          cellType: "link_commasep"
        });
      let items = [];
      links.forEach((l) => {
        let groupLinks = [];
        l.links.forEach((link) => {
          groupLinks.push({ name: link.websiteName, link: link.url });
        });
        console.log(groupLinks);
        // count += l.links.length;
        items.push({ type: {name: l.type}, link_list: {link_commasep: groupLinks} });
      });
      entry.items = items;
      entry.count = count;
      return entry;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
.fixed-title {
  position: sticky;
  top: 0;
  background-color: #f1efec;
  z-index: 10;
  padding-left: 10px;
  padding-top: 10px;
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
