<template>
  <div class="w-100">
    <div
      class="col-sm-12 font-weight-bold text-left d-flex justify-content-between"
    >
      <div>
        <span class="font-weight-bold text-left mr-2"
          >Arabidopsis paralogs</span
        >
        <b-tooltip target="help-icon2" triggers="hover">
          List of Arabidopsis genes that reside in the same gene family as the
          query Arabidopsis gene.
        </b-tooltip>
        <b-icon id="help-icon2" icon="question-circle-fill"></b-icon>
      </div>
      <div
        v-if="content.paralogs && content.paralogs.length > 0"
        class="d-flex justify-content-right mb-2"
      >
        <b-button
          class="ml-2"
          size="md"
          @click="openNewTab({ type: 'sequences' })"
          >Get Sequences</b-button
        >
        <b-button
          class="ml-2"
          size="md"
          @click="openNewTab({ type: 'paralogs' })"
          >Download AGI IDs</b-button
        >
      </div>
    </div>
    <div class="row">
      <div v-if="!content.paralogs || content.paralogs.length == 0">
        No Paralogs Found
      </div>
      <div v-else class="col-sm-6 scrollable-container pt-2">
        <div
          v-for="(obj, j) in content.paralogs.slice(0, 2)"
          class="row w-100 ml-2"
          :key="j"
        >
          <a class="text-left" :href="obj.link" target="_blank">
            {{ obj.name }}
          </a>
        </div>
        <div v-if="content.paralogs.length > 2" class="row w-100 ml-2">
        <a href="#" @click.prevent="showMore">Show More ...</a>
      </div>
      </div>
    </div>
    <hr />
    <div class="col-sm-6 pb-2 d-flex justify-content-between">
      <div>
        <span class="font-weight-bold text-left mr-2">Plant orthologs</span>
        <b-tooltip target="help-icon" triggers="hover">
          List of plant orthologs of the query Arabidopsis gene.
        </b-tooltip>
        <b-icon id="help-icon" icon="question-circle-fill"></b-icon>
      </div>

      <div class="d-flex justify-content-right mb-2">
        <b-button
          class="ml-2"
          size="md"
          @click="openNewTab({ type: 'orthologs' })"
          >Download Orthologs</b-button
        >
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="row w-100">
          <div class="col-sm-6 font-weight-bold text-left">
            {{ content.eudic_ortho.title }}
          </div>
        </div>
        <div
          v-for="(key, j) in Object.keys(content.eudic_ortho.mapping)"
          class="row w-100"
          :key="j"
        >
          <div class="col-sm-8 text-left">{{ key }}</div>
          <div class="col-sm-4 text-left">
            {{ content.eudic_ortho.mapping[key] }}
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row w-100">
          <div class="col-sm-6 font-weight-bold text-left">
            {{ content.mono_ortho.title }}
          </div>
        </div>
        <div
          v-for="(key, j) in Object.keys(content.mono_ortho.mapping)"
          class="row w-100"
          :key="j"
        >
          <div class="col-sm-8 text-left">{{ key }}</div>
          <div class="col-sm-4 text-left">
            {{ content.mono_ortho.mapping[key] }}
          </div>
        </div>
        <div class="row w-100 mt-2">
          <div class="col-sm-6 font-weight-bold text-left">
            {{ content.other_ortho.title }}
          </div>
        </div>
        <div
          v-for="(key, j) in Object.keys(content.other_ortho.mapping)"
          class="row w-100"
          :key="'ortho' + j"
        >
          <div class="col-sm-8 text-left">{{ key }}</div>
          <div class="col-sm-4 text-left">
            {{ content.other_ortho.mapping[key] }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="modal-backdrop">
      <div class="modal-custom">
        <div class="modal-header">
          <h5>All Paralogs</h5>
          <button type="button" class="close" @click="closeModal">&times;</button>
        </div>
        <div class="modal-body">
          <div v-for="(obj, j) in content.paralogs" class="row w-100 ml-2" :key="j">
            <a class="text-left" :href="obj.link" target="_blank">
              {{ obj.name }}
            </a>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" @click="closeModal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {},
  data() {
    return {
      showModal: false,
    };
  },
  props: ["content", "treeId"],
  methods: {
    openNewTab(obj) {
      if (obj.type) {
        if (obj.type == "orthologs") {
          let link =
            "https://phg-orthologs-download-17.s3.us-west-2.amazonaws.com/" +
            this.treeId +
            "_ortholog.txt";
          window.open(link, "_blank");
        } else if (obj.type == "paralogs") {
          let link =
            "https://phg-paralogs-download-17.s3.us-west-2.amazonaws.com/" +
            this.treeId +
            "_paralog.txt";
          window.open(link, "_blank");
        }
        // else if (obj.type == "sequences") {
        //   let link = `${process.env.VUE_APP_OLD_TAIR_URL}/servlet/Search?type=gene&search_action=sendToSequenceDLByNames`;
        //   this.meta.agis.forEach((id) => {
        //     link += "&name=" + id;
        //   });
        //   window.open(link, "_blank");
        // }
      }
    },
    showMore() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>
<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
}

.modal-custom {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-body {
  max-height: 400px;
  overflow-y: auto;
}

.modal-footer {
  text-align: right;
}
</style>
