<template>
	<div
            v-if="b.type == 'comment_list'"
            class="col-sm-10 d-flex flex-column justify-content-start"
          >
            <div v-if="b.showComments">
				<div v-if="b.comments && b.comments.length == 0">
					<div v-for="(c, i) in displayedComments(b)" :key="i" class="m-2">
						<div v-if="c.profile" class="d-flex justify-content-start">
						<a :href="c.profile.url" target="_blank">{{
							c.profile.name
						}}</a
						>, {{ c.date_posted }}
						</div>
						<div class="d-flex justify-content-start">
						<a
							v-if="c.comment.url"
							class="text-left text-overflow"
							:href="c.comment.url"
							target="_blank"
							>{{ c.comment.text }}</a
						>
						<span class="text-left text-overflow" v-else>{{
							c.comment.text
						}}</span>
						</div>
					</div>
			  </div>
			  <div v-else>
				<span class="m-2">No comments available</span>
			  </div>
            </div>
            <div class="d-flex justify-content-left mb-2">
              <b-button class="ml-1" size="sm" disabled
                >Add My Comment</b-button
              >
              <b-button
                class="ml-1"
                size="sm"
                @click="
                  {
                    b.showComments = !b.showComments;
                  }
                "
                ><span v-if="b.showComments">Hide Comments</span
                ><span v-else>Show Comments</span></b-button
              >
              <!-- <b-button
				  class="ml-2"
				  size="md"
				  @click="
					{
					  b.showRecent = !b.showRecent;
					}
				  "
				  ><span v-if="b.showRecent">Show All Comments</span
				  ><span v-else>Show Recent Comments</span></b-button
				> -->
            </div>
          </div>
</template>

<script>
export default {
  props: {
    b: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
	};
},
methods: {
	displayedComments(b) {
		if(!b.comments) {
			return [];
		}
		if (b.showRecent) {
			return b.comments.slice(0, 5);
		} else {
			return b.comments;
		}
	},
}
};
</script>

<style>
</style>